import Me from '../images/danne.webp';
import { Mail } from 'react-feather';

export const HomeTopSection = () => {

  return(
    <>
     <div className="home-top-section">
        <div className="portrait-container">
          <img src={Me}
               id="me"
               width="478"
               height="315"
               draggable="false"
               alt="Daniel Hessling" />
               <div className="name-box">
               <div className="flex-column">
               <span className="">Daniel Hessling</span>
               <span className="">Frontend Developer</span>
               </div>
               </div>
        </div>
        <div className="name-container">
          <div className="centered">
          <span className="firstname">Daniel Hessling</span>
          <span className="lastname">Frontend Developer</span>
        </div>
        </div>
        <div className="social-container">
          <div className="flex social">
            <div><a href="https://www.linkedin.com/in/danielhessling/" target="_blank" rel="noreferrer"><span>LinkedIn</span></a><span className="dot">&#9679;</span></div>
            <div><a href="https://twitter.com/dannehess" target="_blank" rel="noreferrer"><span>Twitter</span></a><span className="dot">&#9679;</span></div>
            <div><a href="https://www.facebook.com/daniel.hessling.1/" target="_blank" rel="noreferrer"><span>Facebook</span></a></div>
          </div>
          <div className="mail-container">
          <span className="mail"><Mail /><a href="mailto:hello@danielhessling.se" rel="noreferrer">hello@danielhessling.se</a></span>
        </div>
        </div>
        </div>
        </>
  )
}