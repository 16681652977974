import { Link } from 'react-router-dom';

export const WorkSection = () => {

  return(
    <section className="mob-pb-48 black">
    <div className="section-heading">
    <h2>Work</h2>
    </div>
    <div className="section-info">
    <p>
      Currently I'm working as a Frontend developer at <strong>Bonnier Healthcare Sweden</strong> in Stockholm city.
    </p>
    <p>
      My work there includes maintaining existing websites and develop new features with Vanilla JS, React, SASS, Silverstripe 3, PHP.
    </p>
    <div className="separator"></div>
    <p>
      If you want to know more about me, don't hesitate to <Link to="/contact">contact me</Link>.
    </p>
    </div>
    </section>
  )
}