import { Link } from 'react-router-dom';
import LogoImage from '../images/logo.webp';

export const Logo = () => {

  return(
    <div className="logo-wrapper">
    <Link to="/">
    <img src={LogoImage}
         alt="Logo"
         id="logo"
         width="56"
         height="56"
         draggable="false" />
    </Link>
    </div>
  )
}