import { useState } from 'react';
import { ProjectSkeleton } from './ProjectSkeleton';

export const Project = props => {

  const { id, title, image, link} = props;
  const [loaded, setLoaded] = useState(false);

  const handleImageLoaded = () => {
    setLoaded(true);
  }
  
  return(
    <>
    <div className="project" key={id} style={{display: loaded? 'block': 'none'}}>
      <a href={link} target="_blank" rel="noreferrer" className="project-link">
        <div className="project-image">
          <img src={image}
               alt={title}
               width="409"
               height="210"
               draggable="false" 
               onLoad={handleImageLoaded}
               style={{display: loaded? 'block': 'none'}}
               />
        </div>
      </a>
        <div className="project-title" style={{display: loaded? 'block': 'none'}}>
        <a href={link} target="_blank" rel="noreferrer" className="project-link">
        {title}
        </a>
        </div>
    </div>
    {!loaded && <ProjectSkeleton/>}
    </>
  )
}