import React from 'react';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({children}) => {

  let user = localStorage.getItem('user') || undefined;

  return user
  ? children
  : <Navigate to="/" />

}