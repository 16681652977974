import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';
import { Header } from './components/Header';
import { Home } from './views/Home';
import { Projects } from './views/Projects';
import { Contact } from './views/Contact';
import { Admin } from './views/Admin';
// import { Register } from './components/Register';

export const App = () => {

  return(
    <div className="wrapper">
      <Router>
      <ScrollToTop />
      <Header />
        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/projects' element={<Projects />} />
            {/* <Route exact path='/register' element={<Register />} /> */}
            <Route exact path='/contact' element={<Contact />} />
            <Route exact path='/admin' element={<PrivateRoute><Admin /></PrivateRoute>} />
        </Routes>
        </Router>
    </div>
  )
}