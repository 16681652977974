export const KnowledgeSection = () => {

  return(
    <section className="green">
    <div className="section-heading">
    <h2>Experience</h2>
    </div>
    <div className="section-info">
     <div className="knowledge-items">
            <div className="knowledge-item">HTML</div>
            <div className="knowledge-item">CSS</div>
            <div className="knowledge-item">SASS</div>
            <div className="knowledge-item">JAVASCRIPT</div>
            <div className="knowledge-item">JQUERY</div>
            <div className="knowledge-item">REACT</div>
            <div className="knowledge-item">PHP</div>
            <div className="knowledge-item">SQL</div>
            <div className="knowledge-item">SILVERSTRIPE</div>
            <div className="knowledge-item">GIT</div>
            <div className="knowledge-item">WORDPRESS</div>
            <div className="knowledge-item">PHOTOSHOP</div>
          </div>
    </div>
    </section>
  )
}