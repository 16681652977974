import { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { ThumbsUp } from 'react-feather';

export const ContactFormSection = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  // const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  const form = useRef();
  const email_service = process.env.REACT_APP_EMAIL_SERVICE;
  const email_userid = process.env.REACT_APP_EMAIL_USERID;
  const email_template = process.env.REACT_APP_EMAIL_TEMPLATE;

  function validateEmail (email) {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

  const sendMail = e => {
    e.preventDefault();

    const obj = {name, email, message};
    // const empties = Object.keys(obj).filter(key => obj[key] === null || obj[key] === undefined || obj[key] === "");

    if(obj.name && validateEmail(obj.email) && obj.message){
      
       emailjs.sendForm(email_service, email_template, form.current, email_userid)
      .then((result) => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 6000);
      }, (error) => {
      });

    }else{
      // setErrors([...empties]);
      setError(true)
      setTimeout(() => {
        setError(false);
      }, 6000);
    }
  }

  return(
    <section className="black section-tb-48 mob-pb-48">
    <div className="section-heading">
    <h2 className="mob-mb-12">Contact me</h2>
    </div>
    <div className="section-info">
    <form id="contactform" ref={form}>
    <div className="form-inputs">
    <div className="form-field">
    <input 
      type="text" 
      placeholder="Name" 
      name="name"
      value={name}
      onChange={e => setName(e.target.value)}
      />
    </div>
    <div className="form-field">
    <input 
      type="email" 
      placeholder="Email"
      name="email"
      value={email}
      onChange={e => setEmail(e.target.value)}
      />
    </div>
    </div>
    <div className="form-field">
    <textarea 
      placeholder="Message"
      value={message}
      name="message"
      onChange={e => setMessage(e.target.value)}
      ></textarea>
    </div>
    <div className="form-actions response-box">
    <div className="form-response">
     {error ?
    <span className="form-msg error-msg">
    Fill the form
    </span>
    : null }
    {success ?
    <span className="form-msg">
    <ThumbsUp /> Message sent!
    </span>
    : null }
    </div>
     <button className="primary-btn" onClick={sendMail}>Send</button>
      </div>
      </form>
    </div>
    </section>
  )
}