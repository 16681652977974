import { useState, useEffect } from 'react';
import { LoginForm } from './LoginForm';
import { MobileLoginForm } from './MobileLoginForm';
import { Link } from 'react-router-dom';
import { Key, Facebook, Linkedin, Twitter } from 'react-feather';
import CV from '../cv_daniel_hessling.pdf';

export const Nav = props => {

  const [toggle, setToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [mobnavtoggle, setMobNavToggle] = useState(false);

  const setToggleLogin = () => {
    setToggle(!toggle);
  }

  const setToggleMobileLogin = prevChe => {
    if(mobnavtoggle){
      setMobNavToggle(false);
    }
    setMobileToggle(!mobileToggle);
  }

  const setMobNav = () => {
    if(mobileToggle){
      setMobileToggle(false);
    }
    setMobNavToggle(!mobnavtoggle);
  }

  const handleClickAway = () => {
    setToggle(false);
    setMobileToggle(false);
  }
  
  useEffect(() => {
    if(mobnavtoggle === true || mobileToggle === true){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflow = 'scroll';
    }
  }, [mobnavtoggle, mobileToggle]);
 
  return(
    <>
    <nav>
    <Link to="/">ABOUT</Link>
    <Link to="/projects">PROJECTS</Link>
    <Link to="/contact">CONTACT</Link>
    <span className="key" onClick={e => setToggleLogin()}>
    <Key />
    </span>
    {toggle
    ?
    <LoginForm setToggleLogin={setToggleLogin} handleClickAway={handleClickAway} />
    : null }
    </nav>
    <div className="mobile-navigation">
    <span className="mobile-key" onClick={e => setToggleMobileLogin()}>
    <Key />
    </span>
    {mobileToggle
     ?
    <MobileLoginForm setToggleMobileLogin={setToggleMobileLogin} setMobileToggle={setMobileToggle} handleClickAway={handleClickAway} />
    : null }
    <span className="hamburger" onClick={e => setMobNav()}>
    {!mobnavtoggle
    ? (<><span></span>
     <span></span>
     <span></span></>)
    : 
    <>
    <span className="close"></span>
    <span className="close"></span>
    </>
     }
    </span>
    </div>
    {mobnavtoggle
    ?
    <div className="mobile-nav">
    <div className="links">
      <Link to="/" onClick={e => setMobNavToggle(false)}>ABOUT</Link>
      <Link to="/projects" onClick={e => setMobNavToggle(false)}>PROJECTS</Link>
      <Link to="/contact" onClick={e => setMobNavToggle(false)}>CONTACT</Link>
      </div>
      <div className="mob-social-links">
       <div><a href="https://www.linkedin.com/in/danielhessling/" target="_blank" rel="noreferrer"><span><Linkedin /></span><span>LinkedIn</span></a><span className="dot">&#9679;</span></div>
            <div><a href="https://twitter.com/dannehess" target="_blank" rel="noreferrer"><span><Twitter /></span><span>Twitter</span></a><span className="dot">&#9679;</span></div>
            <div><a href="https://www.facebook.com/daniel.hessling.1/" target="_blank" rel="noreferrer"><span><Facebook /></span><span>Facebook</span></a></div>
      </div>
    </div>
    : null }
    </>
  )
}