import { HomeTopSection } from '../components/HomeTopSection';
import { AboutSection } from '../components/AboutSection';
import { WorkSection } from '../components/WorkSection';
import { KnowledgeSection } from '../components/KnowledgeSection';

export const Home = () => {

  return(
      <div className="container info-container">
      <HomeTopSection />
      <AboutSection />
      <KnowledgeSection />
      <WorkSection />
      </div>
  )
}