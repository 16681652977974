import { ContactTopSection } from '../components/ContactTopSection';
import { ContactInfoSection } from '../components/ContactInfoSection';
import { ContactFormSection } from '../components/ContactFormSection';

export const Contact = () => {

  return(
    <div className="container info-container">
    {/* <ContactTopSection /> */}
    <ContactInfoSection />
    <ContactFormSection />
    </div>
  )
}