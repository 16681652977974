import { Facebook, Twitter, Linkedin } from 'react-feather';
import Avatar from '../images/avatar.webp';

export const ContactInfoSection = () => {

  return(
    <section className="white section-tb-48 contact-info-section">
    <div className="section-heading hide-heading">
    <h2>Info</h2>
    </div>
    <div className="section-info">
    <p>
    If you want to contact me, use the contactform below
    or send me an email <a href="mailto:hello@danielhessling.se" rel="noreferrer">hello@danielhessling.se</a>.
    </p>
    <p>You can also get in touch with me on social media:</p>
    <div className="contact-social-links">
       <div><a href="https://www.linkedin.com/in/danielhessling/" target="_blank" rel="noreferrer"><span><Linkedin /></span><span>LinkedIn</span></a></div>
            <div><a href="https://twitter.com/dannehess" target="_blank" rel="noreferrer"><span><Twitter /></span><span>Twitter</span></a></div>
            <div><a href="https://www.facebook.com/daniel.hessling.1/" target="_blank" rel="noreferrer"><span><Facebook /></span><span>Facebook</span></a></div>
      </div>
      <img src={Avatar} width="240" id="avatar" />
    </div>
    </section>
  )
}