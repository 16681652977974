import { Link } from 'react-router-dom';

export const AboutSection = () => {

  const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10);

  const age = getAge('1988-12-21');

  return(
    <section className="white">
    <div className="section-heading">
    <h2>About me</h2>
    </div>
    <div className="section-info">
     <p>
      My name is Daniel Hessling and I'm a {age} years old Frontend developer. I studied Frontend development at Nackademin in Stockholm between 2018-2020.
    </p>
    <p>
      I love learning new things and I make new projects as soon as I get the chance. Lately I've got a big interest of learning backend, mostly PHP and NodeJS.
    </p>
    <p>
    On this website you'll find projects I've made on my sparetime just for fun. <Link to="/projects">Click here</Link> to checkout my projects.
    </p>
    </div>
    </section>
  )
}