import React, { useEffect, useState } from "react";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const LoginForm = props => {

  let navigate = useNavigate();

  const { setToggleLogin, handleClickAway } = props;
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [userError, setUserError] = useState(false);
  const [passError, setPassError] = useState(false);

  const handleLogin = e => {
    e.preventDefault();

    let userObj = { username, password };

//  axios.post('https://dhcm.herokuapp.com/login', userObj)
//       .then(response => {
//         console.log('res', response);
//         if(response.status === 200 && response.statusText === 'OK'){
//           setPassError(false);
//           setUserError(false);
//           localStorage.setItem('user', JSON.stringify(response.data));
//           setToggleLogin(false);
//           navigate('/admin');
//         }
//         if(response.status === 200 && response.data === 'wrong password'){
//           setPassError(true);
//         }
//       }).catch(error => {
//         setPassError(true);
//         setUserError(true);
//       });

    // console.log('userobj', userObj);
    // console.log('stringified', JSON.stringify(userObj));
    // localStorage.setItem('user', JSON.stringify(userObj));
    // let user = JSON.parse(localStorage.getItem('user'));
    // if(user){
    //   console.log('should work');
    //   setToggleLogin();
    //   navigate('/admin');
    // }
  }

  return(
    <ClickAwayListener onClickAway={handleClickAway}>
    <div className="login-form" data-id="loginform">
    <form onSubmit={handleLogin}>
      <input 
        type="text" 
        value={username}
        onChange={e => setUserName(e.target.value)}
        placeholder="Username" 
        className={userError ? 'error-border' : ''}
        />
      <input 
        type="password" 
        value={password}
        onChange={e => setPassword(e.target.value)}
        placeholder="Password" 
        className={passError ? 'error-border' : ''}
        />
      <div className="form-actions">
        <button type="button" className="secondary-btn" onClick={setToggleLogin}>Close</button>
        <button type="submit" className="primary-btn" disabled>Log in</button>
      </div>
      </form>
    </div>
    </ClickAwayListener>
  )
}