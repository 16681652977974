import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const MobileLoginForm = props => {

  let navigate = useNavigate();

  const { setToggleMobileLogin, setMobileToggle } = props;
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [userError, setUserError] = useState(false);
  const [passError, setPassError] = useState(false);

  const handleLogin = e => {
    e.preventDefault();
    let userObj = { username, password };

  axios.post('https://dhcm.herokuapp.com/login', userObj)
      .then(response => {
        if(response.status === 200 && response.statusText === 'OK'){
          setPassError(false);
          setUserError(false);
          localStorage.setItem('user', JSON.stringify(response.data));
          setToggleMobileLogin(false);
          navigate('/admin');
        }
        if(response.status === 200 && response.data === 'wrong password'){
          setPassError(true);
        }
      }).catch(error => {
        setPassError(true);
        setUserError(true);
      });

    // console.log('userobj', userObj);
    // console.log('stringified', JSON.stringify(userObj));
    // localStorage.setItem('user', JSON.stringify(userObj));
    // let user = JSON.parse(localStorage.getItem('user'));
    // if(user){
    //   console.log('should work');
    //   setToggleLogin();
    //   navigate('/admin');
    // }
  }

  return(
    <div className="mobile-login-form" data-id="mobileloginform">
    <form onSubmit={handleLogin}>
      <div className="mobile-form">
      <input 
        type="text" 
        value={username}
        onChange={e => setUserName(e.target.value)}
        placeholder="Username" 
        className={userError ? 'error-border' : ''}
        />
      <input 
        type="password" 
        value={password}
        onChange={e => setPassword(e.target.value)}
        placeholder="Password" 
        className={passError ? 'error-border' : ''}
        />
      <div className="form-actions">
        <button type="button" className="secondary-btn" onClick={setToggleMobileLogin}>Close</button>
        <button type="submit" className="primary-btn" disabled>Log in</button>
      </div>
      </div>
      </form>
      <div className="backdrop" onClick={() => setMobileToggle(false)}></div>
    </div>
  )
}