import { Project } from '../components/Project';
import Dashboard from '../images/projects/dashboard.webp';
import MyFriends from '../images/projects/myfriends.webp';
import ToDo from '../images/projects/todo.webp';
import GithubUsers from '../images/projects/githubusers.webp';
import Imagegallery from '../images/projects/imagegallery2.webp';
import Bills from '../images/projects/bills.webp';
import Spinners from '../images/projects/spinners.webp';

export const Projects = () => {

    const portfolio_projects = [
    {
    id: 1,
    title: 'Spinners',
    image: Spinners,
    link: "https://dannehess.github.io/spinners/"
    },
    {
    id: 2,
    title: 'MyFriends',
    image: MyFriends,
    link: "https://myfriends-1e96e.firebaseapp.com/"
    },
    {
    id: 3,
    title: 'Dashboard',
    image: Dashboard,
    link: "https://dashboard-7dec3.firebaseapp.com/"
    },
     {
    id: 4,
    title: 'Todo',
    image: ToDo,
    link: "https://todo-68fa3.firebaseapp.com/"
    },
    {
    id: 5,
    title: 'Image gallery',
    image: Imagegallery,
    link: "https://imagegallery-f6a8c.firebaseapp.com/"
    },
    {
    id: 6,
    title: 'Github users',
    image: GithubUsers,
    link: "https://githubusers-5c30b.firebaseapp.com/"
    },
    {
    id: 7,
    title: 'Bills',
    image: Bills,
    link: "https://bills-1b6df.firebaseapp.com/"
    },
  ];

  return(
    <>
    <div className="projects">
   {portfolio_projects.map(project => {
     const { id, title, image, link} = project;
     return(
       <Project id={id} title={title} image={image} link={link} key={id} />
     )
   })}
    </div>
    </>
  )
}